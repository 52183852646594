import React from 'react';
        import './App.css';

        function App() {
        return (
<div className="App">
  <header className="App-header">
    <h1>Welcome to My Website</h1>
    <p>My name is Qasem Al-Keldi</p>
    <p>You can find here everything about me</p>
  </header>
</div>
        );
        }

        export default App;
